<template>
  <div id="homowork">
      <quillEditor @EditorFrom="fnInfo" :conter="content" style="height:100%"></quillEditor>
  </div>
</template>

<script>
import quillEditor from "../components/quill-edit/index.vue";

export default {
  name: "homework",
  components: {
    quillEditor,
  },
  computed: {
    homeworkId() {
      return this.$route.query.homeworkId;
    },
  },
  created() {
    window.exportData = this.exportData;
    window.introduceData = this.introduceData;
  },
  data() {
    return {
      content: "",
      contnetInfo:"",
    };
  },
  methods: {
    fnInfo(val){
      this.contnetInfo=val;
    },
    //引入数据
    introduceData(val) {
      this.content = String(val);
    },
    //导出数据
    exportData(){
      return this.contnetInfo
    }
  },
};
</script>

<style >
#homowork {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar{display:none;}
</style>
