<template>
  <quill-editor
    class="editor"
    v-model="content"
    ref="myQuillEditor"
    @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
    @change="onEditorChange($event)"
    :options="quillOption"
  ></quill-editor>
</template>
<script>
import quillConfig from "./quill";
export default {
  name: "Editor",
  props: {
    conter: {
      type: String,
    },
  },
  data() {
    return {
      content: null,
      editorOption: {},
      quillOption: quillConfig,
    };
  },
  watch: {
    conter(val) {
      this.content = val;
    },
  },
  methods: {
    onEditorBlur() {},
    onEditorFocus() {
      //获得焦点事件
    },
    onEditorChange(e) {
      //内容改变事件
      this.$emit("EditorFrom", e.html);
    },
  },
};
</script>

<style>
.ql-editor {
  height: 100%;
}
.ql-toolbar.ql-snow {
  display: none !important;
}
.ql-container.ql-snow {
  border: none !important;
}
</style>
